<template>
  <div>
    <v-container>
      <v-row align="center" justify="end">
        <v-col cols="12">
          <div class="text-h6">{{ title }}</div>
        </v-col>
        <!-- <v-col v-if="currMemberRole._isManager" class="py-0" cols="4">
          <v-btn class="add-new-btn" block rounded color="primary" small @click="clickRegister">
            {{ register_btn }}
          </v-btn>
        </v-col> -->
      </v-row>
      <v-row align="center" justify="start">
        <v-col cols="12">
          <v-text-field ref="searchField" v-model="search" hide-details label="搜尋" prepend-inner-icon="ri-search-line"
            single-line></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-list>
      <template v-for="item in filterList">
        <v-list-item :key="`item_${item.id}`" :disabled="item.id === selected.id" @click="clickSwitchIdentity(item)">
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
        <v-divider :key="`divider_${item.id}`"></v-divider>
      </template>
    </v-list>
  </div>
</template>

<script lang="babel" type="text/babel">
import {mapGetters} from 'vuex'
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Object,
      default: () => null,
    },
    title: {
      type: String,
      default: () => '',
    },
    register_btn: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    search: ''
  }),
  mounted(){
  },

  methods: {
    clickSwitchIdentity(item) {
      this.$apopup.base({
        title: `確定要切換成[${item.name}]嗎？`,
        content: ``,
        applyCallback: () => {
          this.$emit('switchIdentity', item)
        },
      })
    },
    clickRegister() {
      this.$emit('register')
    }
  },
  computed: {
    ...mapGetters('member', ['currMemberRole']),
    filterList() {
      const search = this.search.toLowerCase()
      if (!search) return this.list
      return this.list.filter(item => {
        const name = item.name.toLowerCase()
        return name.indexOf(search) > -1
      })
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
.text-h6 
  text-align: center
</style>
